<template>
    <div>
      {{ user.name }}
    </div>
</template>
<script>
export default {
    props:{
        user: Object
    }
}
</script>
<style>
    
</style>